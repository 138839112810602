<script>
import EnrollmentService from '@/services/EnrollmentService'
import QueryParameterService from '@/services/QueryParameterService'
export default {
	name: 'Landing',
	async created () {
		await EnrollmentService.reset()
		QueryParameterService.cacheQueryParameters()
	},
	render () {
		// Ignore redundant navigation errors
		return this.$router.replace({ name: 'intro' })
			.catch(() => {})
	}
}
</script>
